import { createRouter, createWebHistory } from 'vue-router';
import { useApplicationStore } from '/stores/application';


const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('/components/pages/Home.vue'), 
        meta: { title: '' }
    },
    {
        path: '/properties-for-sale',
        name: 'properties-for-sale',
        component: () => import('/components/pages/PropertiesForSale.vue'), 
        meta: { 
            title: 'Properties For Sale',
            // layout: 'ListingsLayout'
        }
    },
    {
        path: '/sections-for-sale',
        name: 'sections-for-sale',
        component: () => import('/components/pages/SectionsForSale.vue'), 
        meta: { 
            title: 'Sections For Sale',
        }
    },
    {
        path: '/sections-for-sale/:slug',
        name: 'section-detail',
        component: () => import('/components/pages/SectionDetail.vue'), 
        meta: { 
            title: 'Section Detail',
            layout: 'ListingDetailLayout'
        }
    },
    {
        path: '/homes-for-sale',
        name: 'homes-for-sale',
        component: () => import('/components/pages/HomesForSale.vue'), 
        meta: { 
            title: 'Homes For Sale',
        }
    },
    {
        path: '/homes-for-sale/:slug',
        name: 'home-detail',
        component: () => import('/components/pages/HomeDetail.vue'), 
        meta: { 
            title: 'Home Detail',
            layout: 'ListingDetailLayout'
        }
    },
    {
        path: '/neighbourhood',
        name: 'neighbourhood',
        component: () => import('/components/pages/Neighbourhood.vue'), 
        meta: { title: 'Neighbourhood' }
    },
    {
        path: '/life-in-arbor-green',
        name: 'life-in-arbor-green',
        component: () => import('/components/pages/LifeInArborGreen.vue'), 
        meta: { title: 'Life in Arbor Green' }
    },
    {
        path: '/life-in-rolleston',
        name: 'life-in-rolleston',
        component: () => import('/components/pages/LifeInRolleston.vue'), 
        meta: { title: 'Life in Rolleston' }
    },
    {
        path: '/journal',
        name: 'journal',
        component: () => import('/components/pages/Journal.vue'), 
        meta: { title: 'Journal' }
    },
    {
        path: '/journal/:slug',
        name: 'journal-entry',
        component: () => import('/components/pages/JournalEntry.vue'), 
        meta: { title: 'Journal Entry' }
    },
    {
        path: '/documents',
        name: 'documents',
        component: () => import('/components/pages/Documents.vue'), 
        meta: { title: 'Documents' }
    },
    {
        path: '/faqs',
        name: 'faqs',
        component: () => import('/components/pages/Faqs.vue'), 
        meta: { title: 'Frequently Asked Questions' }
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('/components/pages/About.vue'), 
        meta: { title: 'About' }
    },
    {
        path: '/enquire',
        name: 'enquire',
        component: () => import('/components/pages/Enquire.vue'), 
        meta: { title: 'Enquire' }
    },
    {
        path: '/legal',
        name: 'legal',
        component: () => import('/components/pages/Legal.vue'), 
        meta: { title: 'Legal' }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'error',
        component: () => import('/components/pages/Error.vue'),
        meta: {
            title: 'Error 404',
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        const appStore = useApplicationStore();
        
        if (appStore.preserveScrollPosition) {
            appStore.setPreserveScrollPosition(false);
            return false;  // Prevents scrolling
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    const appStore = useApplicationStore();
    // reset meta for next page
    appStore.setPageLoaded(false)
    appStore.setTitle('')
    appStore.setDescription(appStore.defaultDescription)
    // if (from.path !== to.path) {  // Check for an actual route change
    // }
    next();
});

export default router;